var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{staticClass:"pb-5 mt-5",style:('min-width: 100% !important'),attrs:{"id":"tableBoxWeek"}},[_c('tr',[_c('th',{attrs:{"colspan":"1"}}),_c('th',{attrs:{"colspan":"1"}}),_vm._l((_vm.daysWeek),function(day){return _c('th',{key:day,staticStyle:{"text-transform":"capitalize"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","flex-direction":"column"}},[_c('div',{staticClass:"pa-0 rounded-pill",staticStyle:{"background-color":"var(--v-primary-base)","width":"90% !important","height":"30px","color":"var(--v-gris3-base)","font-size":"13px","font-weight":"200","line-height":"30px","cursor":"default"}},[_vm._v(" "+_vm._s(_vm.$d(new Date(day), "weekDay"))+" ")]),(day != _vm.today)?_c('div',{staticClass:"my-2 mt-3 rounded-circle",staticStyle:{"border":"1px solid var(--v-primary-base)","height":"45px","width":"45px","line-height":"43px","color":"var(--v-primary-base)","font-weight":"400","font-size":"25px","cursor":"pointer"},on:{"click":function($event){_vm.btn = 'day';
            _vm.date = day;}}},[_vm._v(" "+_vm._s(_vm.$d(new Date(day), "day"))+" ")]):_c('div',{staticClass:"my-2 mt-3 rounded-circle",staticStyle:{"background-color":"var(--v-primary-base)","height":"45px","width":"45px","line-height":"45px","color":"var(--v-gris3-base)","font-weight":"400","font-size":"25px","cursor":"pointer"},on:{"click":function($event){_vm.btn = 'day';
            _vm.date = day;}}},[_vm._v(" "+_vm._s(_vm.$d(new Date(day), "day"))+" ")])])])})],2),_vm._l((_vm.hours),function(h,i){return _c('tr',{key:h},[(_vm.hours.length > i + 1)?_c('td',{style:(h.indexOf(':00') === -1 ? 'visibility: hidden; border-top:0px' : '')},[_c('span',[_vm._v(_vm._s(h))])]):_c('td',{},[_c('span',[_vm._v(_vm._s(+_vm.hours[i].split(":")[0])+":"+_vm._s(_vm.hours[i].split(":")[1]))])]),_c('td',{style:(h.indexOf(':00') === -1 ? ' border-top:0px' : ''),attrs:{"colspan":"1","width":"1px"}}),_vm._l((_vm.daysWeek),function(day){return [(
          _vm.boxesWeekHours(h, day).reserva && _vm.boxesWeekHours(h, day).colspan > 0
        )?_c('td',{key:day,style:((" cursor: default; border: 2px solid var(--v-primary-base); background-color:" + (_vm.boxesWeekHours(h, day).color))),attrs:{"rowspan":_vm.boxesWeekHours(h, day).colspan},on:{"click":function($event){_vm.showEvent({
            event: _vm.boxesWeekHours(h, day).reserva,
            box: _vm.boxesWeekHours(h, day).reserva.boxes,
            nativeEvent: $event,
          })}}},[(_vm.boxesWeekHours(h, day).colspan === 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({staticClass:"tattooer_name single-line"},on),[_vm._v(" . ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.boxesWeekHours(h, day).reserva.tattooer.user.first_name)+" "+_vm._s(_vm.boxesWeekHours(h, day).reserva.tattooer.user.last_name)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.boxesWeekHours(h, day).reserva.tattooer.user.first_name)+" "+_vm._s(_vm.boxesWeekHours(h, day).reserva.tattooer.user.last_name)+" ")])],1):(_vm.rowspanBox(h, day))?_c('td',{key:day,class:[
          {
            gris2: _vm.checkTime()(
              h,
              day,
              _vm.$store.getters['auth/isStudio']
                ? _vm.$store.state.auth.user.studio.id
                : _vm.$store.state.boxes.studioId,
              _vm.$store.getters['auth/isStudio']
                ? _vm.filtersWeek.tattooer
                : _vm.$store.state.auth.user.tattooer.id,
              _vm.filtersWeek.box_id
            ),
          } ],style:(h.indexOf(':00') === -1 ? ' border-top:0px' : '')}):_vm._e()]})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }