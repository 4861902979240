<template>
  <v-simple-table
    class="pb-5 mt-5"
    id="tableBoxWeek"
    :style="'min-width: 100% !important'"
  >
    <tr>
      <th colspan="1"></th>
      <th colspan="1"></th>
      <th :key="day" v-for="day in daysWeek" style="text-transform: capitalize">
        <div style="display: flex; align-items: center; flex-direction: column">
          <div
            class="pa-0 rounded-pill"
            style="
              background-color: var(--v-primary-base);
              width: 90% !important;
              height: 30px;
              color: var(--v-gris3-base);
              font-size: 13px;
              font-weight: 200;
              line-height: 30px;
              cursor: default;
            "
          >
            {{ $d(new Date(day), "weekDay") }}
          </div>
          <div
            v-if="day != today"
            class="my-2 mt-3 rounded-circle"
            style="
              border: 1px solid var(--v-primary-base);
              height: 45px;
              width: 45px;
              line-height: 43px;
              color: var(--v-primary-base);

              font-weight: 400;
              font-size: 25px;
              cursor: pointer;
            "
            @click="
              btn = 'day';
              date = day;
            "
          >
            {{ $d(new Date(day), "day") }}
          </div>
          <div
            v-else
            class="my-2 mt-3 rounded-circle"
            style="
              background-color: var(--v-primary-base);
              height: 45px;
              width: 45px;
              line-height: 45px;
              color: var(--v-gris3-base);

              font-weight: 400;
              font-size: 25px;
              cursor: pointer;
            "
            @click="
              btn = 'day';
              date = day;
            "
          >
            {{ $d(new Date(day), "day") }}
          </div>
        </div>
      </th>
    </tr>

    <tr :key="h" v-for="(h, i) in hours">
      <td
        v-if="hours.length > i + 1"
        :style="
          h.indexOf(':00') === -1 ? 'visibility: hidden; border-top:0px' : ''
        "
      >
        <span>{{ h }}</span>
      </td>

      <td v-else style="">
        <span>{{ +hours[i].split(":")[0] }}:{{ hours[i].split(":")[1] }}</span>
      </td>
      <td
        colspan="1"
        width="1px"
        :style="h.indexOf(':00') === -1 ? ' border-top:0px' : ''"
      ></td>
      <template v-for="day in daysWeek">
        <td
          :key="day"
          v-if="
            boxesWeekHours(h, day).reserva && boxesWeekHours(h, day).colspan > 0
          "
          :rowspan="boxesWeekHours(h, day).colspan"
          :style="` cursor: default; border: 2px solid var(--v-primary-base); background-color:${
            boxesWeekHours(h, day).color
          }`"
          @click="
            showEvent({
              event: boxesWeekHours(h, day).reserva,
              box: boxesWeekHours(h, day).reserva.boxes,
              nativeEvent: $event,
            })
          "
        >
          <v-tooltip bottom v-if="boxesWeekHours(h, day).colspan === 1">
            <template v-slot:activator="{ on }">
              <div class="tattooer_name single-line" v-on="on">
                .
                <!--

                    {{
                      !tabs.tab_day
                        ? tattooerHours(h, box).reserva.tattooer.user.first_name
                        : tattooerHours(h, box).reserva.appointments
                        ? tattooerHours(h, box).reserva.appointments.customer
                            .user.first_name
                        : tattooerHours(h, box).reserva.tattooer.user.first_name
                    }}
                    {{
                      !tabs.tab_day
                        ? tattooerHours(h, box).reserva.tattooer.user.last_name
                        : tattooerHours(h, box).reserva.appointments
                        ? tattooerHours(h, box).reserva.appointments.customer
                            .user.last_name
                        : tattooerHours(h, box).reserva.tattooer.user.last_name
                    }}
                   -->
              </div>
            </template>
            {{ boxesWeekHours(h, day).reserva.tattooer.user.first_name }}
            {{ boxesWeekHours(h, day).reserva.tattooer.user.last_name }}
          </v-tooltip>
          <span v-else>
            {{ boxesWeekHours(h, day).reserva.tattooer.user.first_name }}
            {{ boxesWeekHours(h, day).reserva.tattooer.user.last_name }}
          </span>
        </td>
        <td
          :key="day"
          v-else-if="rowspanBox(h, day)"
          :style="h.indexOf(':00') === -1 ? ' border-top:0px' : ''"
          v-bind:class="[
            {
              gris2: checkTime()(
                h,
                day,
                $store.getters['auth/isStudio']
                  ? $store.state.auth.user.studio.id
                  : $store.state.boxes.studioId,
                $store.getters['auth/isStudio']
                  ? filtersWeek.tattooer
                  : $store.state.auth.user.tattooer.id,
                filtersWeek.box_id
              ),
            },
          ]"
        ></td>
      </template>
    </tr>
  </v-simple-table>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "CalendarWeekBox",
  data() {
    return {
      boxWeekHours: null,
    };
  },
  computed: {
    ...mapState("boxes", [
      "dateState",
      "tabs",
      "filtersState",
      "boxes",
      "tattooers",
      "filtersWeekState",
    ]),
    ...mapGetters("boxes", [
      "hoursRow",
      "timeTo",
      "timeFrom",
      "mins",
      "today",
      "daysWeek",
    ]),

    ...mapGetters("timetables", [
      "hours",
      "firstHourGlobal",
      "lastHourGlobal",
      "mins",
    ]),
    filtersWeek: {
      get() {
        return this.filtersWeekState;
      },
      set(value) {
        this.$store.commit("boxes/SET_FILTERS_WEEK", value);
      },
    },

    btn: {
      get() {
        return this.tabs.btn;
      },
      set(value) {
        this.$store.commit("boxes/SET_TABS", { value, tab: "btn" });
      },
    },
    date: {
      get() {
        return this.dateState;
      },
      set(value) {
        this.$store.commit("boxes/SET_DATE", value);
      },
    },
  },
  mounted() {
    this.filterTable();
  },

  watch: {
    filtersWeek: {
      handler() {
        this.filterTable();
      },
      deep: true,
    },
    filtersState: {
      handler() {
        this.filterTable();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("boxes", ["showWeekTableBox"]),
    ...mapMutations("boxes", ["showEvent"]),
    filterTable() {
      this.loading = true;

      this.showWeekTableBox({
        dates: {
          from: this.filtersState.from,
          to: this.filtersState.to,
        },
        id_box: this.filtersWeek.box,
      }).then((boxes) => {
        if (boxes) {
          this.boxWeekHours = boxes.data;

          this.loading = false;
        }
      });
    },
    ...mapGetters("timetables", ["checkTime"]),
    boxesWeekHours(h, day) {
      let c = null;
      if (this.boxWeekHours) c = this.boxWeekHours[day];
      if (c) {
        // //console.log(c);
        let hs = c.find((x) => x.start_time == h);

        if (hs) {
          let span = 0;
          let end = parseInt(hs.end_time);
          let start = parseInt(hs.start_time);
          for (let x = start; x <= end; x++) {
            this.mins.forEach((y) => {
              let t = `${x < 10 ? "0" + x : x}${y}`;
              //console.log(t);
              if (hs.start_time <= t && hs.end_time > t) span++;
            });
          }

          return { reserva: hs, colspan: span, color: hs.tattooer.color };
        }
      }

      return { reserva: null, colspan: 1 };
    },

    rowspanBox(h, day) {
      let c = null;
      if (this.boxWeekHours) c = this.boxWeekHours[day];
      if (c) {
        let hs = c.find((x) => {
          return h > x.start_time && h < x.end_time;
        });
        ////console.log(n.boxes_tattooer[0].start_time == h);

        if (hs) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>


<style lang="sass" scoped>
#tableBoxWeek
  min-width: 1000px
  padding-left: 40px
  width: auto
  background-color: var(--v-gris3-base)
  tr:first-child th
    border: 0
    font-size: 16px !important
  tr:nth-child(2) th
    border-top: 0
  td:first-child,th:first-child
    width: 50px
    position: absolute
  td:first-child, th:first-child
    border-left: 0
    border-top: 0
    border-right: 0
    min-width: 50px
  span
    display: block
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
  td:first-child span
    position: absolute
    margin-top: -10px
    margin-left: -50px
  tr:last-child td
    border-bottom: 1px solid var(--v-primary-base)
  tr:last-child td:first-child
    border-bottom: 0
    span:first-child
      margin-top: -4px !important

  td, th
    border-top: 1px solid var(--v-primary-base)
    border-right: 1px solid var(--v-primary-base)
    padding: 0 !important
    min-width: 100px
    border-color: var(--v-primary-base) !important

    max-width: 100px

    text-align: center

  tr:last-child
    td
      height: 0
      max-height: 0
  td
    height: 12px
    div.single-line
      height: 100%
      width: 100%
      font-size: 0
  td:nth-child(2), th:nth-child(2)
    min-width: 20px !important
    width: 20px

    border-left: 0
    border-bottom: 0
    border-top: 1px solid var(--v-primary-base)
</style>